import React, { useState } from "react"

import {
    Container, Col, Row, Collapse
} from 'reactstrap';
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import image1 from '../static/images/step1.png'
import image2 from '../static/images/step2.png'
import image3 from '../static/images/step3.png'
import image4 from '../static/images/step4.png'
import myself from '../static/images/myself.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

const Home = () => {

    const [q1, setQ1] = useState(false);
    const [q2, setQ2] = useState(false);
    const [q3, setQ3] = useState(false);
    const [q4, setQ4] = useState(false);
    const [q5, setQ5] = useState(false);
    const [q6, setQ6] = useState(false);

    const steps = [
        {
            title: "CHOISISSEZ",
            content: "Tasses, vases, pichets, bols, coquetiers... Il y en a forcément un pour vous ! Avec près de cinquante modèles au choix. Laissez vous tenter par celui qui vous plaira le plus. (À partir de 14€)",
            css: "bg-gold ",
            img: image1,
            alt: "Choisir une pièce en céramique à décorer au café Le Biscuit Iodé à Concarneau."
        },
        {
            title: "DECOREZ",
            content: "Au biscuit iodé, on ne connaît pas le syndrome de la page blanche. À l'aide d'un éventail d'inspirations, vous aurez 2h pour réaliser votre chef-d'œuvre.",
            css: "bg-terra",
            img: image2,
            alt: "Décorer une pièce en céramique personnalisée au café Le Biscuit Iodé à Concarneau."

        },
        {
            title: "PATIENTEZ",
            content: "Pour sublimer les couleurs de vos objets en céramique tout en les protégeant, ils passeront entre mes mains pour être émaillés et cuits dans les plus brefs délais.",
            css: "bg-gold",
            img: image3,
            alt: "Processus de cuisson de la céramique au café céramique Le Biscuit Iodé à Concarneau."
        },
        {
            title: "RECUPEREZ",
            content: "Le grand jour est arrivé... Venez admirer votre pièce après cuisson. Vous êtes libre de l’utiliser comme objet du quotidien, en décoration ou même de l’offrir en cadeau !",
            css: "bg-gold",
            img: image4,
            alt: "Récupérer sa céramique personnalisée prête au café Le Biscuit Iodé à Concarneau."
        }
    ]



    return (
        <div className="">
            
            <section id="home" className=" ">

                <div className="my-container" style={{ height: "100vh" }}>
                    <div className="hero container-fluid px-0">
                        <div className="hero-filter">
                            <Container className="d-flex flex-column justify-content-between h-100 w-100 text-center py-3 py-md-5">
                                <Col md={8}>
                                    <h2 style={{ lineHeight: 0.8, fontSize: "7.5vh" }} className="w-100 w-md-50 better-together text-uppercase text-white text-start text-center text-md-start pt-md-5 pt-2">Faites parler votre creativite lors d une pause cafe en choisissant votre objet ceramique prefere</h2>
                                </Col>
                                <div className="align-items-center w-100 pb-md-5 pb-2">
                                    <h1 className="text-white better-together text-uppercase"><b>Le cafe ceramique de Concarneau</b></h1>
                                    <Link className="mx-auto nav-link secondary-btn btn-lg white text-white py-2 px-5 " style={{ width: "fit-content", fontSize: "20px" }} target="_blank" to="https://octotable.com/book/restaurant/834320/booking/new">Réserver</Link>
                                </div>

                            </Container>
                        </div>

                    </div>

                </div>
            </section>
            <section id="concept" className="py-3">
                <Container fluid className="my-container pt-5 pb-0 ">
                    <h2 className="pt-2 ps-md-5 ps-0 text-marine better-together huge-2 text-uppercase text-center text-md-start">Une experience en plusieurs etapes</h2>
                    <Row className="px-5" >
                        {
                            steps.map((step, index) => {
                                return (
                                    <Col key={index.toString() + "step"} md={3} className="mt-5" style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="text-center position-relative shadow" style={{ marginTop: "75px" }}>
                                            <LazyLoadImage alt={step.alt} className="position-absolute" style={{ top: "-6em", left: "0", right: "0", height: "12em", marginInline: "auto" }} src={step.img} />
                                            <div className={"my-rounded p-4 h-100"} style={{ backgroundColor: "#F0EDE2" }}>
                                                <h3 style={{ paddingTop: "75px" }} className=" pb-2"><span className="small">{index + 1}. &nbsp;</span><span className="better-together huge">{step.title}</span></h3>
                                                <p className="text-start patrick-hand">{step.content}</p>
                                            </div>
                                        </div>


                                    </Col>
                                )
                            })
                        }

                    </Row>
                    <div className="w-100 d-flex justify-content-center pt-5">
                        <Link className="text-gold nav-link primary-btn py-2 px-5 cursor-pointer" target="_blank" to="https://octotable.com/book/restaurant/834320/booking/new">Réserver</Link>
                    </div>


                </Container>
            </section>

            <section id="about-me" className="py-3">
                <Container fluid className="my-container pt-5 pb-0">
                    <div className="container-fluid my-rounded px-0 shadow " style={{ backgroundColor: "#E5B8A0" }}>
                        <div className="p-5 ">
                            <h2 style={{ lineHeight: "40px" }} className="better-together huge-2 text-uppercase text-center text-md-start">Vous souhaitez en savoir plus sur moi <span className="patrick-hand small">?</span></h2>
                            <Col className='mx-auto' md={9}>

                                <Row className="py-4 mx-auto">
                                    <Col md={6} className="my-auto w-100  text-center">
                                        <LazyLoadImage width={"100x"} src={myself} alt="Moi c'est chloé, venez me rencontrer au Biscuit iodé, le café céramique de Concarneau." />
                                    </Col>
                                    <Col md={12} className="my-auto pt-3" style={{ lineHeight: "30px" }} >
                                        <div className="position-relative">

                                            <div className='position-relative' style={{ zIndex: "100 !important", fontSize: "18px", textAlign: 'justify' }}>Moi, c’est Chloé. 
                                                Passionnée d’aquarelle, j’aime les moments de partage autour des arts créatifs. L’ouverture de mon café céramique dans ma belle région de Bretagne est pour moi une aventure enrichissante et pleine de sens. 
                                                Le biscuit iodé est un lieu chaleureux où détente et convivialité se rencontrent. Venez éveiller votre imagination autour d’un café et de quelques gourmandises. J’ai hâte de vous accueillir et de vivre cette belle aventure avec vous !
                                            </div>

                                            <div className="position-absolute baginda text-gold-transparent" style={{ top: "-35px", left: "-50px", fontSize: "250px", zIndex: "1 !important" }}>"</div>
                                            <div className="position-absolute baginda text-gold-transparent" style={{ bottom: "-160px", right: "-30px", fontSize: "250px", zIndex: "1 !important" }}>"</div>

                                        </div>
                                        <div className="text-end pt-4"><b>Chloé</b></div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </Container>

            </section>

            <section id="faq" className="">

                <Container fluid className="my-container ">
                    <div className="container-fluid my-rounded px-0 shadow" style={{ backgroundColor: "#F0EDE2" }}>
                        <div className="p-5 ">
                            <h2 className="pb-4 ps-md-5 ps-2 text-marine better-together huge-2 text-uppercase text-center text-md-start">FAQ</h2>
                            <Col className='mx-auto' md={9}>

                                <div className="py-4 mx-auto">

                                    <div className="border-bottom-gold pt-3">
                                        <div className="pointer d-flex justify-content-between px-3" color="primary" onClick={() => { setQ1(!q1) }} style={{ marginBottom: '1rem', fontSize: "18px" }}>
                                            <div className="my-auto">Faut-il être doué en dessin pour venir faire un atelier au biscuit iodé ?</div>
                                            <div style={{ fontSize: "26px" }} className="text-gold"><FontAwesomeIcon className="my-auto" size="xs" icon={q1 ? faMinus: faPlus} /></div>
                                        </div>
                                        <Collapse isOpen={q1} className="px-3" >

                                            <p>
                                                Absolument pas ! Plusieurs techniques, inspirations et accessoires pour tous les niveaux seront mis à disposition. Vous aurez le temps nécessaire pour sublimer votre objet et passer un bon moment. Votre objet sera unique et surtout parfaitement imparfait ! Vous verrez, vous direz fièrement à vos proches : C'est moi qui l'ai fait !
                                            </p>

                                        </Collapse>
                                    </div>
                                    <div className="border-bottom-gold pt-3">
                                        <div className="pointer d-flex justify-content-between px-3 " color="primary" onClick={() => { setQ2(!q2) }} style={{ marginBottom: '1rem', fontSize: "18px" }}>
                                            <div className="my-auto">Je souhaite venir avec mes enfants faire un café céramique, quel âge minimum doivent-ils avoir ?</div>
                                            <div style={{ fontSize: "26px" }} className="text-gold"><FontAwesomeIcon className="my-auto" size="xs" icon={q2 ? faMinus: faPlus} /></div>
                                        </div>
                                        <Collapse className="px-3" isOpen={q2} >

                                            <p>Cette activité céramique au biscuit iodé est appropriée pour les enfants dès lors que votre enfant peut tenir un pinceau dans ses mains ! Nous avons sélectionné des objets adaptés pour les plus petits comme pour les plus grands. <br /> Globalement à partir de 3 ans.</p>

                                        </Collapse>
                                    </div>
                                    <div className="border-bottom-gold pt-3">
                                        <div className="pointer d-flex justify-content-between px-3" color="primary" onClick={() => { setQ3(!q3) }} style={{ marginBottom: '1rem', fontSize: "18px" }}>
                                            <div className="my-auto">Quels prix pour réaliser cette activité ?</div>
                                            <div style={{ fontSize: "26px" }} className="text-gold"><FontAwesomeIcon className="my-auto" size="xs" icon={q3 ? faMinus: faPlus} /></div>
                                        </div>
                                        <Collapse className="px-3" isOpen={q3} >

                                            <p>
                                                Les objets les moins onéreux seront à partir de 14€. Selon votre choix, le prix variera. Des objets en céramique comme des tasses, des assiettes, des bols, des coquetiers... seront à votre disposition, vous trouverez forcément votre bonheur.
                                            </p>

                                        </Collapse>
                                    </div>
                                    <div className="border-bottom-gold pt-3">
                                        <div className="pointer d-flex justify-content-between px-3" color="primary" onClick={() => { setQ4(!q4) }} style={{ marginBottom: '1rem', fontSize: "18px" }}>
                                            <div className="my-auto">Je suis en vacances dans la région, malheureusement avec le temps de cuisson je ne pourrai venir récupérer mon objet, comment fait-on ?</div>
                                            <div style={{ fontSize: "26px" }} className="text-gold"><FontAwesomeIcon className="my-auto" size="xs" icon={q4 ? faMinus: faPlus} /></div>
                                        </div>
                                        <Collapse className="px-3" isOpen={q4} >

                                            <p>
                                                Ne vous inquiétez pas. Des envois postaux sont prévus pour que vos souvenirs de vacances en céramique vous parviennent. Quoi de mieux que d'utiliser votre propre création au quotidien ?<br />En espérant vous revoir très vite à Concarneau !
                                            </p>

                                        </Collapse>
                                    </div>
                                    <div className="border-bottom-gold pt-3">
                                        <div className="pointer d-flex justify-content-between px-3" color="primary" onClick={() => { setQ5(!q5) }} style={{ marginBottom: '1rem', fontSize: "18px" }}>
                                            <div className="my-auto">Quel est le délai nécessaire pour récupérer mon objet en céramique que j’ai réalisé ?</div>
                                            <div style={{ fontSize: "26px" }} className="text-gold"><FontAwesomeIcon className="my-auto" size="xs" icon={q5 ? faMinus: faPlus} /></div>
                                        </div>
                                        <Collapse className="px-3" isOpen={q5} >

                                            <p>
                                                En moyenne, sous un délai de 10 jours. Bien entendu, dès lors que vos chefs-d'oeuvre sortent du four, vous serez les premiers avertis !
                                            </p>

                                        </Collapse>
                                    </div>
                                    <div className="border-bottom-gold pt-3">
                                        <div className="pointer d-flex justify-content-between px-3" color="primary" onClick={() => { setQ6(!q6) }} style={{ marginBottom: '1rem', fontSize: "18px" }}>
                                            <div className="my-auto">Je suis particulier/ professionnel et je souhaite privatiser le biscuit iodé pour un atelier céramique, est ce envisageable ?</div>
                                            <div style={{ fontSize: "26px" }} className="text-gold"><FontAwesomeIcon className="my-auto" size="xs" icon={q6 ? faMinus: faPlus} /></div>
                                        </div>
                                        <Collapse className="px-3" isOpen={q6} >

                                            <p>
                                                Bien sûr ! Il est tout à fait possible de privatiser le Biscuit iodé pour organiser vos anniversaires, EVJF et EVG, ou tout autre évènement. Concernant les modalités, il suffit de nous contacter par mail à contact@biscuit-iode.fr ou par téléphone au 09.82.74.12.91. Nous organiserons ensemble l'évènement de votre choix pour que cette expérience vous reste un superbe souvenir.
                                            </p>

                                        </Collapse>
                                    </div>



                                </div>
                            </Col>
                        </div>
                    </div>
                </Container>

            </section>
        </div>
    )


}

export default Home;
